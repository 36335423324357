@charset "utf-8";

body {
  overflow: auto;
}
// // Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// // Set your brand colors
// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
// $beige-lighter: #eff0eb;

// // Update Bulma's global variables
// $family-sans-serif: 'Nunito', sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// // Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/form.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/layout/footer.sass";
@import "../node_modules/bulma/sass/grid/tiles.sass";
@import "../node_modules/bulma/sass/helpers/_all.sass";

#logo {
  .logo-letter {
    fill: rgb(165, 165, 165);
    stroke: grey;
    stroke-width: 0.2;
    stroke-miterlimit: 4;
    stroke-dasharray: 100;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-name: draw;
    animation-iteration-count: 1;
    animation-direction: alternate;
    stroke-dashoffset: 100;
    animation-delay: 5s;
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: 100;
    }

    100% {
      stroke-dashoffset: 0;
      fill: rgb(0, 27, 114);
      stroke: transparent;
      stroke-width: 0.25;
    }
  }
}

.header {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.button {
  border-radius: 0;
}

.title {
  color: gray;
}

.prime-logo {
  fill: rgb(2, 48, 2);
}

.footer {
  bottom: 0;
  position: absolute;
  left:0;
  right: 0;
  padding-bottom: 2em;
}
